import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function BlogPost({ data }) {
  const { post } = data

  const seo = {
    title: post.frontmatter.title,
    description: post.frontmatter.excerpt,
    url: 'https://medicusdukla.pl' + post.frontmatter.slug
  }

  return (
    <Layout pageClass={null} bg={null}>
      <SEO
        title={ seo.title}
        description={ seo.description }
        url={ seo.url }
      />
     <section data-g="container">
       <div className="o-article" data-g="grid">
         <div data-g="4 12@md">
           <GatsbyImage className="a-img" image={getImage(post.fields.background)} alt={post.frontmatter.title} />
         </div>
         <div data-g="4 6@md" className="o-article__content">
           <span> {post.frontmatter.date}</span>
           <h1>
             {post.frontmatter.title}
           </h1>
           <div dangerouslySetInnerHTML={{__html: post.html}} />
         </div>
       </div>
     </section>
    </Layout>
  )
}

export const pageQuery = graphql`
    query BlogPost($slug: String) {
        post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date
                background
                slug
                excerpt
            }
            fields {
                background {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 80, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
        }
    }`
